@use 'src/src/app/scss/abstracts' as *;

.page-playtest-home {
  $block-name: &;

  padding-top: 0;
  padding-bottom: 100px;

  @include mq($until: lg) {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  &__title {
    margin: 0;
    font-size: 86px;
    font-weight: $font-weight-4xl;
    line-height: 1;
    letter-spacing: -6.002px;
    text-transform: uppercase;
    text-align: center;
    font-style: italic;
    margin-bottom: 70px;

    // letters background
    background: linear-gradient(341deg, #fff7c7 21.44%, #c9a9ff 59.2%);
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @include mq($until: lg) {
      font-size: 48px;
      letter-spacing: -3.387px;
      margin-bottom: 50px;
    }
  }

  &__entry-button {
    text-align: center;
    margin-bottom: 70px;

    &:empty {
      display: none !important;
    }

    @include mq($until: lg) {
      margin-bottom: 50px;
    }
  }
}
